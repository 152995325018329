import { Job } from 'generated/apolloComponents'
import useTranslation from 'next-translate/useTranslation'
import { getContractTypes } from '@/helpers/utils'
import DropdownString from '../DropdownString'
import style from './JobListItem.module.scss'

interface IProps {
  job: Job
}

const JobListItemDetails = ({ job }: IProps) => {
  const { t } = useTranslation('common')

  const locations = []

  if (job.remote) locations.push('Remote')
  if (job.city && job.city.length > 0)
    locations.push(...(job.city?.split(',') as string[]))

  // naming due to https://github.com/aralroca/next-translate#5-plurals
  const count = locations.length

  return (
    <div className={` ${style.item__details}`}>
      {locations.length > 0 && (
        <DropdownString
          icon
          title={`${locations[0]} +${count - 1}`}
          list={locations}
        />
      )}
      <div className='flex items-start'>
        <svg className='w-6 h-6 mr-1' fill='currentColor'>
          <use href='#heroicon-document-text-icon' />
        </svg>
        <span>
          {job.employmentType === 'full_time'
            ? getContractTypes(job)
                .map((item) => t(`${item}`))
                .join('/')
            : t(`common:${job.employmentType}`)}
        </span>
      </div>
      {job.experienceLevel && (
        <div className='flex items-start'>
          <svg className='w-6 h-6 mr-1' fill='currentColor'>
            <use href='#heroicon-square-3-stack-3d-icon' />
          </svg>
          <span>{t(`common:${job.experienceLevel}`)}</span>
        </div>
      )}
    </div>
  )
}

export default JobListItemDetails
